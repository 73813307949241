import * as generated from "./generated/api";
import _merge from 'lodash-es/merge';
import _set from 'lodash-es/set';
import * as portableFetch from "portable-fetch";
import {Auth0Client} from "@3kraft/auth0-spa-js";
import {auth0ClientOptions, auth0GetTokenSilentlyOptions} from "../utils/auth0Options";
import {config} from "../utils/clientConfigWrapper";

console.debug('api-wrapper: loading ...');

export const auth0 = new Auth0Client(auth0ClientOptions);

abstract class BaseAPI extends generated.BaseAPI {

    public initialized: Promise<void>;
    protected baseOptions: any;

    /**
     * Use this method to load data from the server to initialize.
     */
    public loadData() {
        return Promise.resolve();
    }

    public initialize() {
        // noinspection JSUnusedLocalSymbols
        this.initialized = this.loadData().catch(error => {
            // Do nothing because during initialization loadData may fail because
            // of missing authorization.
        });
        return this;
    }

    protected authorize(options?: any): Promise<any> {
        const requestOptions = _merge({}, this.baseOptions, options);

        return auth0.getTokenSilently(auth0GetTokenSilentlyOptions)
            .then(token => {
                console.debug('auth0 token', token);
                _set(requestOptions, ['headers', 'Authorization'], `Bearer ${token}`);

                return requestOptions;
            });
    }

    protected emptyBody(options: any) {
        return _merge({}, options, {
            body: JSON.stringify({})
        });
    }
}

class AppApi extends BaseAPI implements generated.AppApi {

    private delegate: generated.AppApi;

    constructor(protected basePath: string, protected fetch: generated.FetchAPI, baseOptions: any) {
        super(null, basePath, fetch);
        this.baseOptions = baseOptions;
        this.delegate = new generated.AppApi(null, basePath, fetch);
    }

    getAppState(options?: any): Promise<generated.AppState> {
        return this.delegate.getAppState(options);
    }

    updateIdeaStatistics(options?: any): Promise<Response> {
        return this.authorize(options).then((options) => this.delegate.updateIdeaStatistics(options));
    }

    updateUserRankings(options?: any): Promise<Response> {
        return this.authorize(options).then((options) => this.delegate.updateUserRankings(options));
    }

    updateUserAwards(options?: any): Promise<Response> {
        return this.authorize(options).then((options) => this.delegate.updateUserAwards(options));
    }

    sendEvaluationReminders(at?: string, options?: any): Promise<Response> {
        return this.authorize(options).then((options) => this.delegate.sendEvaluationReminders(at, options));
    }

    sendImplementationReminders(at?: string, options?: any): Promise<Response> {
        return this.authorize(options).then((options) => this.delegate.sendEvaluationReminders(at, options));
    }

    getAppVars(options?: any): Promise<generated.AppVarsDto> {
        return this.authorize(options).then((options) => this.delegate.getAppVars(options));
    }

    putAppVars(appVars: generated.AppVarsDto, options?: any): Promise<generated.AppVarsDto> {
        return this.authorize(options).then((options) => this.delegate.putAppVars(appVars, options));
    }

    incrementIdeaStateDurations(options?: any): Promise<Response> {
        return this.authorize(options).then(options => this.delegate.incrementIdeaStateDurations(options));
    }
}

class IdeaApi extends BaseAPI implements generated.IdeaApi {
    private delegate: generated.IdeaApi;

    constructor(protected basePath: string, protected fetch: generated.FetchAPI, protected baseOptions: any) {
        super(null, basePath, fetch);
        this.baseOptions = baseOptions;
        this.delegate = new generated.IdeaApi(null, basePath, fetch);
    }

    getIdea(id: number, pageView?: boolean, options?: any): Promise<generated.IdeaDto> {
        return this.authorize(options).then((options) => this.delegate.getIdea(id, pageView, options));
    }

    getIdeas(searchTerm?: string, ranking?: 'newest' | 'highlighted', limit?: number,
             dtoResponseType?: 'full' | 'slim' | 'ref', unacceptedMembers?: boolean, excludeCompleted?: boolean,
             options?: any): Promise<Array<generated.IdeaDto>> {
        return this.authorize(options).then((options) =>
            this.delegate.getIdeas(searchTerm, ranking, limit, dtoResponseType, unacceptedMembers, excludeCompleted,
                options));
    }

    getIdeasDeleted(limit?: number, dtoResponseType?: 'full' | 'slim' | 'ref', options?: any):
        Promise<Array<generated.IdeaDto>> {
        return this.authorize(options).then((options) =>
            this.delegate.getIdeasDeleted(limit, dtoResponseType, options));
    }

    getIdeaActivities(id: number, options?: any): Promise<Array<generated.ActivityLogDto>> {
        return this.authorize(options).then((options) => this.delegate.getIdeaActivities(id, options));
    }

    getIdeaComments(ideaId: number, options?: any): Promise<Array<generated.CommentDto>> {
        return this.authorize(options).then((options) => this.delegate.getIdeaComments(ideaId, options));
    }

    putIdeaLikes(ideaId: number, options?: any): Promise<number> {
        return this.authorize(options).then((options) =>
            this.delegate.putIdeaLikes(ideaId, this.emptyBody(options)));
    }

    postIdeaComment(ideaId: number, commentDto: generated.CommentDto, options?: any): Promise<generated.CommentDto> {
        return this.authorize(options).then((options) =>
            this.delegate.postIdeaComment(ideaId, commentDto, options));
    }

    getIdeaEvaluations(ideaId: number, options?: any): Promise<generated.EvaluationDto[]> {
        return this.authorize(options).then((options) =>
            this.delegate.getIdeaEvaluations(ideaId, options));
    }

    postIdeaEvaluation(ideaId: number, evaluationDto: generated.EvaluationDto, sendMail?: boolean,
                       options?: any): Promise<generated.EvaluationDto> {
        return this.authorize(options).then((options) =>
            this.delegate.postIdeaEvaluation(ideaId, evaluationDto, sendMail, options));
    }

    postIdea(ideaDto: generated.IdeaDto, options?: any): Promise<generated.IdeaDto> {
        return this.authorize(options).then((options) =>
            this.delegate.postIdea(ideaDto, options));
    }

    putIdea(ideaId: number, ideaDto: generated.IdeaDto, options?: any): Promise<generated.IdeaDto> {
        return this.authorize(options).then((options) =>
            this.delegate.putIdea(ideaId, ideaDto, options));
    }

    isIdeaBookmarked(ideaId: number, options?: any): Promise<boolean> {
        return this.authorize(options).then((options) =>
            this.delegate.isIdeaBookmarked(ideaId, options));
    }

    setIdeaBookmarked(ideaId: number, options?: any): Promise<boolean> {
        return this.authorize(options).then((options) =>
            this.delegate.setIdeaBookmarked(ideaId, this.emptyBody(options)));
    }

    unsetIdeaBookmarked(ideaId: number, options?: any): Promise<boolean> {
        return this.authorize(options).then((options) =>
            this.delegate.unsetIdeaBookmarked(ideaId, options));
    }

    getRelatedIdeas(ideaId: number, includeRelatedBy?: boolean, options?: any): Promise<Array<generated.IdeaDto>> {
        return this.authorize(options).then((options) =>
            this.delegate.getRelatedIdeas(ideaId, includeRelatedBy, options));
    }

    putRelatedIdea(ideaId: number, relatedIdeaId: number, options?: any): Promise<Response> {
        return this.authorize(options).then((options) =>
            this.delegate.putRelatedIdea(ideaId, relatedIdeaId, this.emptyBody(options)));
    }

    removeRelatedIdea(ideaId: number, relatedIdeaId: number, options?: any): Promise<Response> {
        return this.authorize(options).then((options) =>
            this.delegate.removeRelatedIdea(ideaId, relatedIdeaId, options));
    }

    getIdeaMembers(ideaId: number, options?: any): Promise<Array<generated.IdeaMemberDto>> {
        return this.authorize(options).then((options) =>
            this.delegate.getIdeaMembers(ideaId, options));
    }

    getIdeasCount(sinceDays?: number, ideastate?, options?: any): Promise<number> {
        return this.authorize(options).then((options) =>
            this.delegate.getIdeasCount(sinceDays, ideastate, options));
    }

    addIdeaMember(ideaId: number, ideaMemberDto: generated.IdeaMemberDto, options?: any): Promise<generated.IdeaMemberDto> {
        return this.authorize(options).then((options) =>
            this.delegate.addIdeaMember(ideaId, ideaMemberDto, options));
    }

    deleteIdea(ideaId: number, options?: any): Promise<Response> {
        return this.authorize(options).then((options) =>
            this.delegate.deleteIdea(ideaId, options));
    }

    restoreIdea(ideaId: number, options?: any): Promise<generated.IdeaDto> {
        return this.authorize(options).then((options) =>
            this.delegate.restoreIdea(ideaId, options));
    }

    shareIdea(ideaId: number, recipients: generated.ShareIdeaEmailDto, options?: any): Promise<Response> {
        return this.authorize(options).then((options) =>
            this.delegate.shareIdea(ideaId, recipients, options));
    }

    getCertificate(ideaId: number, userId: number, options?: any): Promise<any> {
        return this.authorize(options).then((options) => {
            options.headers['Accept'] = 'application/pdf';
            const localVarFetchArgs = generated.IdeaApiFetchParamCreator(this.configuration)
                .getCertificate(ideaId, userId, options);
            return portableFetch((<any>this.delegate).basePath + localVarFetchArgs.url, localVarFetchArgs.options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.arrayBuffer();
                    } else {
                        throw response;
                    }
                });
        });
    }

    issueCertificate(ideaId: number, certificateInfo: generated.CertificateInfo, options?: any): Promise<Response> {
        return this.authorize(options).then((options) =>
            this.delegate.issueCertificate(ideaId, certificateInfo, options));
    }

    getIdeaStateDurations(options?: any): Promise<generated.IdeaStateDurationDto[]> {
        return this.authorize(options).then((options) =>
            this.delegate.getIdeaStateDurations(options));
    }

    getIdeaLiked(ideaId: number, options?: any): Promise<boolean> {
        return this.authorize(options).then(options => this.delegate.getIdeaLiked(ideaId, options));
    }

    getIdeaUserRating(ideaId: number, options?: any): Promise<number> {
        return this.authorize(options).then(options => this.delegate.getIdeaUserRating(ideaId, options));
    }

    putIdeaRatings(ideaId: number, rating: number, options?: any): Promise<generated.Rating> {
        return this.authorize(options).then(options => this.delegate.putIdeaRatings(ideaId, rating, options));
    }

    getIdeaEvaluationsSummary(ideaId: number, options?: any): Promise<generated.EvaluationDto> {
        return this.authorize(options).then(options => this.delegate.getIdeaEvaluationsSummary(ideaId, options));
    }
}

class IdeaMemberApi extends BaseAPI implements generated.IdeaMemberApi {

    private delegate: generated.IdeaMemberApi;

    constructor(protected basePath: string, protected fetch: generated.FetchAPI, protected baseOptions: any) {
        super(null, basePath, fetch);
        this.baseOptions = baseOptions;
        this.delegate = new generated.IdeaMemberApi(null, basePath, fetch);
    }

    deleteIdeaMember(ideaMemberId: number, options?: any): Promise<Response> {
        return this.authorize(options).then((options) =>
            this.delegate.deleteIdeaMember(ideaMemberId, options));
    }

    getIdeaMember(ideaMemberId: number, options?: any): Promise<generated.IdeaMemberDto> {
        return this.authorize(options).then((options) =>
            this.delegate.getIdeaMember(ideaMemberId, options));
    }

    putIdeaMember(ideaMemberId: number, ideaMemberDto: generated.IdeaMemberDto,
                  options?: any): Promise<generated.IdeaMemberDto> {
        return this.authorize(options).then((options) =>
            this.delegate.putIdeaMember(ideaMemberId, ideaMemberDto, options));
    }
}

class EvaluationApi extends BaseAPI implements generated.EvaluationApi {

    private delegate: generated.EvaluationApi;

    constructor(protected basePath: string, protected fetch: generated.FetchAPI, protected baseOptions: any) {
        super(null, basePath, fetch);
        this.baseOptions = baseOptions;
        this.delegate = new generated.EvaluationApi(null, basePath, fetch);
    }

    getEvaluation(evaluationId: number, options?: any): Promise<generated.EvaluationDto> {
        return this.authorize(options).then((options) =>
            this.delegate.getEvaluation(evaluationId, options));
    }

    putEvaluation(evaluationId: number, evaluationDto: generated.EvaluationDto, sendMail?: boolean, options?: any):
        Promise<generated.EvaluationDto> {
        return this.authorize(options).then((options) =>
            this.delegate.putEvaluation(evaluationId, evaluationDto, sendMail, options));
    }

    postPostpone(evaluationId: number, postponeDto: generated.PostponeDto, options?: any):
        Promise<generated.EvaluationDto> {
        return this.authorize(options).then((options) =>
            this.delegate.postPostpone(evaluationId, postponeDto, options));
    }

    getEvaluations(excludeCompleted?: boolean, options?: any): Promise<Array<generated.EvaluationDto>> {
        return this.authorize(options).then((options) =>
            this.delegate.getEvaluations(excludeCompleted, options));
    }

    getEvaluationsCount(sinceDays?: number,
                        evaluationState?: "submitted" | "evaluated", options?: any): Promise<number> {
        return this.authorize(options).then((options) =>
            this.delegate.getEvaluationsCount(sinceDays, evaluationState, options));
    }

    deleteEvaluation(evaluationId: number, options?: any): Promise<Response> {
        return this.authorize(options).then((options) => this.delegate.deleteEvaluation(evaluationId, options));
    }

    getEvaluationAssessmentCriteriaTypes(options?: any): Promise<string[]> {
        return this.authorize(options).then((options) => this.delegate.getEvaluationAssessmentCriteriaTypes(options));
    }

    getEvaluationCriteriaTypes(options?: any): Promise<string[]> {
        return this.authorize(options).then((options) => this.delegate.getEvaluationCriteriaTypes(options));
    }
}

class ContentApi extends BaseAPI implements generated.ContentApi {

    private delegate: generated.ContentApi;

    constructor(protected basePath: string, protected fetch: generated.FetchAPI, protected baseOptions: any) {
        super(null, basePath, fetch);
        this.baseOptions = baseOptions;
        this.delegate = new generated.ContentApi(null, basePath, fetch);
    }

    getWidget(id: number, options?: any): Promise<generated.WidgetDto> {
        return this.authorize(options).then((options) => this.delegate.getWidget(id, options));
    }

    getWidgets(widgetType?: "PAGE" | "NEWS", limit?: number, options?: any): Promise<Array<generated.WidgetDto>> {
        return this.authorize(options).then((options) => this.delegate.getWidgets(widgetType, limit, options));
    }

    postWidget(body: generated.WidgetDto, options?: any) {
        return this.authorize(options).then((options) => this.delegate.postWidget(body, options));
    }

    putWidget(widgetId: number, body: generated.WidgetDto, options?: any): Promise<generated.WidgetDto> {
        return this.authorize(options).then((options) => this.delegate.putWidget(widgetId, body, options));
    }

    postUploads(body: Array<generated.UploadDto>, basePath?: string, options?: any): Promise<Array<string>> {
        return this.authorize(options).then((options) => this.delegate.postUploads(body, basePath, options));
    }

    postFormUpload(file: any, options?: any): Promise<string> {
        return this.authorize(options)
            .then(options => {
                const formData = new FormData();
                formData.append('file', file);

                const requestOptions = _merge({}, options, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'Transfer-Encoding': 'chunked'
                    }
                });
                // remove the content type set by authorization
                delete requestOptions.headers['Content-Type'];

                return this.fetch(`${this.basePath}/formUploads`, requestOptions);
            })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
    }

    public deleteWidget(widgetId: number, options?: any) {
        return this.authorize(options).then((options) => this.delegate.deleteWidget(widgetId, options));
    }
}

class UserApi extends BaseAPI implements generated.UserApi {

    private delegate: generated.UserApi;

    constructor(protected basePath: string, protected fetch: generated.FetchAPI, protected baseOptions: any) {
        super(null, basePath, fetch);
        this.baseOptions = baseOptions;
        this.delegate = new generated.UserApi(null, basePath, fetch);
    }

    getAuthenticatedUser(options?: any): Promise<generated.UserDto> {
        return this.authorize(options).then((options) => this.delegate.getAuthenticatedUser(options));
    }

    getUser(value: string, by?: "id" | "username", options?: any): Promise<generated.UserDto> {
        return this.authorize(options).then((options) => this.delegate.getUser(value, by, options));
    }

    getUserActivities(id: number, options?: any): Promise<Array<generated.ActivityLogDto>> {
        return this.authorize(options).then((options) => this.delegate.getUserActivities(id, options));
    }

    getUserComments(userId: number, options?: any): Promise<Array<generated.CommentDto>> {
        return this.authorize(options).then((options) => this.delegate.getUserComments(userId, options));
    }

    getExpertEvaluations(userId: number, options?: any): Promise<Array<generated.EvaluationDto>> {
        return this.authorize(options).then((options) => this.delegate.getExpertEvaluations(userId, options));
    }

    getUserIdeas(userId: number, options?: any): Promise<Array<generated.IdeaDto>> {
        return this.authorize(options).then((options) => this.delegate.getUserIdeas(userId, options));
    }

    getUserMemberships(userId: number, options?: any): Promise<Array<generated.IdeaMemberDto>> {
        return this.authorize(options).then((options) => this.delegate.getUserMemberships(userId, options));
    }

    getBookmarkedIdeas(userId: number, options?: any): Promise<Array<generated.IdeaDto>> {
        return this.authorize(options).then((options) => this.delegate.getBookmarkedIdeas(userId, options));
    }

    getAdminUsers(options?: any): Promise<Array<generated.UserDto>> {
        return this.authorize(options).then((options) => this.delegate.getAdminUsers(options));
    }

    putProfileImage(userProfileImage: generated.UploadDto, options?: any): Promise<generated.UserDto> {
        return this.authorize(options).then((options) => this.delegate.putProfileImage(userProfileImage, options));
    }

    getUsers(searchTerm?: string, limit?: number, dtoResponseType?: 'full' | 'slim' | 'ref', options?: any):
        Promise<Array<generated.UserDto>> {
        return this.authorize(options).then((options) => this.delegate.getUsers(searchTerm, limit, dtoResponseType,
            options));
    }

    getUsersCount(options?: any): Promise<number> {
        return this.authorize(options).then((options) => this.delegate.getUsersCount(options));
    }

    getUserAwards(userId: number, options?: any): Promise<generated.UserAwardsDto> {
        return this.authorize(options).then((options) => this.delegate.getUserAwards(userId, options));
    }

    putHideAdmin(userId: number, hide: string, options?: any): Promise<boolean> {
        return this.authorize(options).then((options) => this.delegate.putHideAdmin(userId, hide, options));
    }

    putExcludeFromRankings(userId: number, exclude: string, options?: any): Promise<boolean> {
        return this.authorize(options).then((options) =>
            this.delegate.putExcludeFromRankings(userId, exclude, options));
    }

    putUserTutorialDismissed(dismissTutorial: string, options?: any): Promise<boolean> {
        return this.authorize(options).then((options) =>
            this.delegate.putUserTutorialDismissed(dismissTutorial, options));
    }

    putUser(userId: number, userDto: generated.UserDto, options?: any): Promise<generated.UserDto> {
        return this.authorize(options).then((options) =>
            this.delegate.putUser(userId, userDto, options));
    }

    deleteUser(userId: number, options?: any): Promise<Response> {
        return this.authorize(options).then((options) => this.delegate.deleteUser(userId, options));
    }

    putUserExternal(userId: number, external: string, options?: any): Promise<boolean> {
        return this.authorize(options).then((options) =>
            this.delegate.putUserExternal(userId, external, options));
    }

    putUserAdmin(userId: number, admin: string, options?: any): Promise<boolean> {
        return this.authorize(options).then((options) =>
            this.delegate.putUserAdmin(userId, admin, options));
    }

    sortAdminUsers(userList: Array<generated.UserDto>, options?: any) {
        return this.authorize(options).then((options) =>
            this.delegate.sortAdminUsers(userList, options));
    }

    putNotificationsRead(userId: number, options?: any): Promise<Date> {
        return this.authorize(options).then((options) =>
            this.delegate.putNotificationsRead(userId, this.emptyBody(options)));
    }
}

class NotificationApi extends BaseAPI implements generated.NotificationApi {

    private delegate: generated.NotificationApi;

    constructor(protected basePath: string, protected fetch: generated.FetchAPI, protected baseOptions: any) {
        super(null, basePath, fetch);
        this.baseOptions = baseOptions;
        this.delegate = new generated.NotificationApi(null, basePath, fetch);
    }

    dismissUserNotification(userNotificationId: number, options?: any): Promise<Response> {
        return this.authorize(options).then((options) =>
            this.delegate.dismissUserNotification(userNotificationId, options));
    }

    getUserNotifications(unread?: boolean,
                         options?: any): Promise<Array<generated.UserNotificationDto>> {
        return this.authorize(options).then((options) =>
            this.delegate.getUserNotifications(unread, options));
    }

    dismissUserNotifications(options?: any): Promise<Response> {
        return this.authorize(options).then((options) =>
            this.delegate.dismissUserNotifications(options));
    }
}

class CommentApi extends BaseAPI implements generated.CommentApi {

    private delegate: generated.CommentApi;

    constructor(protected basePath: string, protected fetch: generated.FetchAPI, protected baseOptions: any) {
        super(null, basePath, fetch);
        this.baseOptions = baseOptions;
        this.delegate = new generated.CommentApi(null, basePath, fetch);
    }

    getComment(commentId: number, options?: any): Promise<generated.CommentDto> {
        return this.authorize(options).then((options) => this.delegate.getComment(commentId, options));
    }

    putCommentLikes(commentId: number, options?: any): Promise<number> {
        return this.authorize(options).then((options) =>
            this.delegate.putCommentLikes(commentId, this.emptyBody(options)));
    }

    postCommentReply(commentId: number, commentDto: generated.CommentDto,
                     options?: any): Promise<generated.CommentDto> {
        return this.authorize(options).then((options) =>
            this.delegate.postCommentReply(commentId, commentDto, options));
    }

    putComment(commentId: number, commentDto: generated.CommentDto, options?: any): Promise<generated.CommentDto> {
        return this.authorize(options).then((options) =>
            this.delegate.putComment(commentId, commentDto, options));
    }

    getComments(limit?: number, options?: any): Promise<Array<generated.CommentDto>> {
        return this.authorize(options).then((options) => this.delegate.getComments(limit, options));
    }

    getCommentsCount(sinceDays?: number, options?: any): Promise<number> {
        return this.authorize(options).then((options) => this.delegate.getCommentsCount(sinceDays, options));
    }
}

class CampaignApi extends BaseAPI implements generated.CampaignApi {

    private delegate: generated.CampaignApi;

    constructor(protected basePath: string, protected fetch: generated.FetchAPI, protected baseOptions: any) {
        super(null, basePath, fetch);
        this.baseOptions = baseOptions;
        this.delegate = new generated.CampaignApi(null, basePath, fetch);
    }

    deleteCampaign(campaignId: number, options?: any): Promise<Response> {
        return this.authorize(options).then((options) => this.delegate.deleteCampaign(campaignId, options));
    }

    getCampaign(campaignId: number, options?: any): Promise<generated.CampaignDto> {
        return this.authorize(options).then((options) => this.delegate.getCampaign(campaignId, options));
    }

    getCampaigns(limit?: number, options?: any): Promise<Array<generated.CampaignDto>> {
        return this.authorize(options).then((options) => this.delegate.getCampaigns(limit, options));
    }

    postCampaign(body: generated.CampaignDto, options?: any): Promise<generated.CampaignDto> {
        return this.authorize(options).then((options) => this.delegate.postCampaign(body, options));
    }

    putCampaign(campaignId: number, body: generated.CampaignDto, options?: any): Promise<generated.CampaignDto> {
        return this.authorize(options).then((options) => this.delegate.putCampaign(campaignId, body, options));
    }
}

class CategoryApi extends BaseAPI implements generated.CategoryApi {

    private delegate: generated.CategoryApi;

    constructor(protected basePath: string, protected fetch: generated.FetchAPI, protected baseOptions: any) {
        super(null, basePath, fetch);
        this.baseOptions = baseOptions;
        this.delegate = new generated.CategoryApi(null, basePath, fetch);
    }

    deleteCategory(categoryId: number, options?: any): Promise<Response> {
        return this.authorize(options).then((options) => this.delegate.deleteCategory(categoryId, options));
    }

    getCategories(options?: any): Promise<Array<generated.CategoryDto>> {
        return this.authorize(options).then((options) => this.delegate.getCategories(options));
    }

    postCategory(body: generated.CategoryDto, options?: any): Promise<generated.CategoryDto> {
        return this.authorize(options).then((options) => this.delegate.postCategory(body, options));
    }

    putCategory(body: generated.CategoryDto, categoryId: number, options?: any): Promise<generated.CategoryDto> {
        return this.authorize(options).then((options) => this.delegate.putCategory(body, categoryId, options));
    }
}

class ActivityLogApi extends BaseAPI implements generated.ActivityLogApi {

    private delegate: generated.ActivityLogApi;

    constructor(protected basePath: string, protected fetch: generated.FetchAPI, protected baseOptions: any) {
        super(null, basePath, fetch);
        this.baseOptions = baseOptions;
        this.delegate = new generated.ActivityLogApi(null, basePath, fetch);
    }

    getActivityLogsCount(sinceDays?: number, activityLogType?: "IDEA_CREATE" | "IDEA_DELETE" | "IDEA_EDIT" | "IDEA_COMMENT" | "IDEA_LIKE" | "IDEA_VIEW" | "IDEA_BOOKMARK" | "IDEA_SHARE" | "IDEA_SUBMIT" | "COMMENT_REPLY" | "COMMENT_LIKE" | "COMMENT_EDIT" | "EVALUATION_CREATED" | "EVALUATION_EDITED" | "EVALUATION_DELETED" | "EVALUATION_SUBMITTED" | "EVALUATION_ASSESSED" | "USER_LOGIN" | "USER_CREATED" | "PROFILE_IMAGE" | "UNKNOWN", options?: any): Promise<number> {
        return this.authorize(options).then((options) =>
            this.delegate.getActivityLogsCount(sinceDays, activityLogType, options));
    }

    queryActivityLogs(activityLogType?: "IDEA_CREATE" | "IDEA_DELETE" | "IDEA_EDIT" | "IDEA_COMMENT" | "IDEA_LIKE" | "IDEA_VIEW" | "IDEA_BOOKMARK" | "IDEA_SHARE" | "IDEA_SUBMIT" | "COMMENT_REPLY" | "COMMENT_LIKE" | "COMMENT_EDIT" | "EVALUATION_CREATED" | "EVALUATION_EDITED" | "EVALUATION_DELETED" | "EVALUATION_SUBMITTED" | "EVALUATION_ASSESSED" | "USER_LOGIN" | "USER_CREATED" | "PROFILE_IMAGE" | "UNKNOWN", since?: string, until?: string, userId?: number, ideaId?: number, options?: any): Promise<generated.ActivityLogDto[]> {
        return this.authorize(options).then((options) =>
            this.delegate.queryActivityLogs(activityLogType, since, until, userId, ideaId, options));
    }

    queryCommentActivityLogs(activityLogType?: "IDEA_CREATE" | "IDEA_DELETE" | "IDEA_EDIT" | "IDEA_COMMENT" | "IDEA_LIKE" | "IDEA_VIEW" | "IDEA_BOOKMARK" | "IDEA_SHARE" | "IDEA_SUBMIT" | "COMMENT_REPLY" | "COMMENT_LIKE" | "COMMENT_EDIT" | "EVALUATION_CREATED" | "EVALUATION_EDITED" | "EVALUATION_DELETED" | "EVALUATION_SUBMITTED" | "EVALUATION_ASSESSED" | "USER_LOGIN" | "USER_CREATED" | "PROFILE_IMAGE" | "UNKNOWN", since?: string, until?: string, userId?: number, options?: any): Promise<generated.CommentActivityLogDto[]> {
        return this.authorize(options).then((options) =>
            this.delegate.queryCommentActivityLogs(activityLogType, since, until, userId, options));
    }

}

class RankingApi extends BaseAPI implements generated.RankingApi {

    private delegate: generated.RankingApi;

    constructor(protected basePath: string, protected fetch: generated.FetchAPI, protected baseOptions: any) {
        super(null, basePath, fetch);
        this.baseOptions = baseOptions;
        this.delegate = new generated.RankingApi(null, basePath, fetch);
    }

    getUserRankingIdeas(limit?: number, options?: any): Promise<generated.UserRankingsDto> {
        return this.authorize(options).then((options) =>
            this.delegate.getUserRankingIdeas(limit, options));
    }

    getUserRankingIdeasRecent(limit?: number, options?: any): Promise<generated.UserRankingsDto> {
        return this.authorize(options).then((options) =>
            this.delegate.getUserRankingIdeasRecent(limit, options));
    }

    getUserRankingPoints(limit?: number, options?: any): Promise<generated.UserRankingsDto> {
        return this.authorize(options).then((options) =>
            this.delegate.getUserRankingPoints(limit, options));
    }

    getUserRankingPointsRecent(limit?: number, options?: any): Promise<generated.UserRankingsDto> {
        return this.authorize(options).then((options) =>
            this.delegate.getUserRankingPointsRecent(limit, options));
    }

    queryUserScores(userScoreType?: "IDEA_SEND" | "COMMENT" | "IDEA_SUBMIT" | "IDEA_SUBMIT_WITH_DESC" | "IDEA_SUBMIT_WITH_IMAGE" | "IDEA_SUBMIT_COMPLETE" | "PROFILE_IMAGE" | "IDEA_MEMBER_REGISTRY" | "IDEA_MEMBER_APPROVAL" | "INITIAL" | "INITIAL_COMMENTS" | "IDEA_IMPLEMENTED", since?: string, until?: string, userId?: number, options?: any): Promise<Array<generated.UserScoreDto>> {
        return this.authorize(options).then((options) =>
            this.delegate.queryUserScores(userScoreType, since, until, userId, options));
    }
}

export const appApi: AppApi = new AppApi(config.endpoint, portableFetch, config.fetchOptions).initialize();
export const ideaApi: IdeaApi = new IdeaApi(config.endpoint, portableFetch, config.fetchOptions).initialize();
export const ideaMemberApi: IdeaMemberApi =
    new IdeaMemberApi(config.endpoint, portableFetch, config.fetchOptions).initialize();
export const contentApi: ContentApi = new ContentApi(config.endpoint, portableFetch, config.fetchOptions).initialize();
export const userApi: UserApi = new UserApi(config.endpoint, portableFetch, config.fetchOptions).initialize();
export const notificationApi: NotificationApi =
    new NotificationApi(config.endpoint, portableFetch, config.fetchOptions).initialize();
export const commentApi: CommentApi = new CommentApi(config.endpoint, portableFetch, config.fetchOptions).initialize();
export const evaluationApi: EvaluationApi =
    new EvaluationApi(config.endpoint, portableFetch, config.fetchOptions).initialize();
export const campaignApi: CampaignApi =
    new CampaignApi(config.endpoint, portableFetch, config.fetchOptions).initialize();
export const categoryApi: CategoryApi =
    new CategoryApi(config.endpoint, portableFetch, config.fetchOptions).initialize();
export const activityLogApi: ActivityLogApi =
    new ActivityLogApi(config.endpoint, portableFetch, config.fetchOptions).initialize();
export const rankingApi: RankingApi =
    new RankingApi(config.endpoint, portableFetch, config.fetchOptions).initialize();
